var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.providers
    ? _c(
        "v-carousel",
        {
          staticClass: "custom",
          attrs: {
            cycle: false,
            height: "350px",
            "show-arrows-on-hover": "",
            "hide-delimiters": ""
          },
          on: {
            change: function($event) {
              return _vm.cardChanged($event)
            }
          }
        },
        [
          _vm._l(_vm.providers, function(item, index) {
            return [
              (index + 1) % _vm.columns === 1 || _vm.columns === 1
                ? _c(
                    "v-carousel-item",
                    { key: index, attrs: { eager: "", src: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "flex-nowrap",
                          staticStyle: { height: "100%" }
                        },
                        [
                          _vm._l(_vm.columns, function(n, col) {
                            return [
                              +index + col < _vm.providers.length
                                ? [
                                    _c(
                                      "v-col",
                                      { key: col },
                                      [
                                        +index + col < _vm.providers.length
                                          ? _c(
                                              "v-card",
                                              {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  "max-width": "350",
                                                  height: "100%"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  { staticClass: "pb-2" },
                                                  [
                                                    _c("v-avatar", [
                                                      _c("img", {
                                                        attrs: {
                                                          src:
                                                            _vm.providers[
                                                              index + col
                                                            ].avatarImgUrl
                                                        }
                                                      })
                                                    ]),
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "pt-0 justify-end align-content-start"
                                                      },
                                                      [
                                                        _vm.isValidValue(
                                                          _vm.providers[
                                                            index + col
                                                          ].providerPageUrl
                                                        )
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "pt-0 text-none",
                                                                attrs: {
                                                                  href:
                                                                    _vm
                                                                      .providers[
                                                                      index +
                                                                        col
                                                                    ]
                                                                      .providerPageUrl,
                                                                  target:
                                                                    "_blank",
                                                                  plain: "",
                                                                  ripple: false,
                                                                  text: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Visit Page"
                                                                ),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      color:
                                                                        "#047ABC"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.mdiLaunch
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-title",
                                                  {
                                                    staticClass:
                                                      "pt-0 pb-0 subtitle-1 font-weight-medium"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.providers[
                                                          index + col
                                                        ].displayName
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm.isValidValue(
                                                  _vm.providers[index + col]
                                                    .specialty
                                                )
                                                  ? _c(
                                                      "v-card-subtitle",
                                                      {
                                                        staticClass:
                                                          "pt-3 pb-0 body-2 font-weight-light"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.providers[
                                                                index + col
                                                              ].specialty
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isValidValue(
                                                  _vm.providers[index + col]
                                                    .telephone
                                                )
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "pt-0 pb-0",
                                                        attrs: { block: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              slot: "prepend",
                                                              small: "",
                                                              color: "#047ABC"
                                                            },
                                                            slot: "prepend"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mdiPhone
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.providers[
                                                              index + col
                                                            ].telephone
                                                          )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("v-divider", {
                                                  staticClass: "card__divider"
                                                }),
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "pt-0 pb-0" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "one-line-overflow"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              slot: "prepend",
                                                              small: "",
                                                              color: "#047ABC"
                                                            },
                                                            slot: "prepend"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.mdiMapMarkerOutline
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.addressPart1(
                                                              _vm.providers[
                                                                index + col
                                                              ].address
                                                            )
                                                          )
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _vm.isValidValue(
                                                  _vm.addressPart2(
                                                    _vm.providers[index + col]
                                                      .address
                                                  )
                                                )
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass: "pt-0 pb-0"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "one-line-overflow"
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "card__hidden__icon",
                                                                attrs: {
                                                                  slot:
                                                                    "prepend",
                                                                  small: ""
                                                                },
                                                                slot: "prepend"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mdiMapMarkerOutline
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.addressPart2(
                                                                  _vm.providers[
                                                                    index + col
                                                                  ].address
                                                                )
                                                              )
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("v-divider", {
                                                  staticClass: "card__divider"
                                                }),
                                                _vm.isValidValue(
                                                  _vm.providers[index + col]
                                                    .status
                                                )
                                                  ? _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "pt-2 pb-0 body-2"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-text-highlight"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.providers[
                                                                  index + col
                                                                ].status
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          "justify-center": "",
                                                          "align-end": ""
                                                        }
                                                      },
                                                      [
                                                        _vm.isValidValue(
                                                          _vm.providers[
                                                            index + col
                                                          ].schedulingUrl
                                                        )
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "carousel-card-btn body-1",
                                                                attrs: {
                                                                  href:
                                                                    _vm
                                                                      .providers[
                                                                      index +
                                                                        col
                                                                    ]
                                                                      .schedulingUrl,
                                                                  backcolor:
                                                                    "#047ABC",
                                                                  block: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Schedule Online"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm.isValidValue(
                                                          _vm.providers[
                                                            index + col
                                                          ].schedulingUrl
                                                        )
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "carousel-card-btn body-1",
                                                                attrs: {
                                                                  href:
                                                                    "tel:" +
                                                                    _vm
                                                                      .providers[
                                                                      index +
                                                                        col
                                                                    ].telephone,
                                                                  backcolor:
                                                                    "#047ABC",
                                                                  block: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Call Provider"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }