var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "ml-16 pl-4", attrs: { "d-flex": "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "restart-button restart-button-content text-capitalize",
          attrs: {
            "aria-label": "restart button",
            color: "#047ABC",
            text: "",
            icon: ""
          },
          on: { click: _vm.onButtonClick }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(_vm._s(_vm.mdiRestart))
          ]),
          _vm._v(" Start Over ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }