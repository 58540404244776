var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message.text &&
    (_vm.message.type === "human" || _vm.message.type === "feedback")
    ? _c("div", { staticClass: "message-text" }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("I say: ")]),
        _vm._v(_vm._s(_vm.message.text) + " ")
      ])
    : _vm.altHtmlMessage && _vm.AllowSuperDangerousHTMLInMessage
    ? _c("div", {
        staticClass: "message-text",
        domProps: { innerHTML: _vm._s(_vm.altHtmlMessage) }
      })
    : _vm.message.text && _vm.shouldRenderAsHtml
    ? _c("div", {
        staticClass: "message-text",
        domProps: { innerHTML: _vm._s(_vm.botMessageAsHtml) }
      })
    : _vm.message.text &&
      (_vm.message.type === "bot" || _vm.message.type === "agent")
    ? _c("div", { staticClass: "message-text bot-message-plain" }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v(_vm._s(_vm.message.type) + " says: ")
        ]),
        _vm._v(
          _vm._s(
            _vm.shouldStripTags
              ? _vm.stripTagsFromMessage(_vm.message.text)
              : _vm.message.text
          ) + " "
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }