var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isUiMinimized
    ? _c(
        "v-app-bar",
        _vm._g(
          {
            class: { minimized: _vm.isUiMinimized },
            attrs: {
              color: _vm.toolbarColor,
              app: "",
              dark: "",
              fixed: "",
              dense: this.$store.state.isRunningEmbedded && !_vm.isUiMinimized,
              "aria-label":
                "Toolbar with sound FX mute button, minimise chat window button and option chat back a step button"
            }
          },
          _vm.toolbarClickHandler
        ),
        [
          _vm.toolbarLogo
            ? _c("img", {
                staticClass: "toolbar-image",
                attrs: {
                  src: _vm.toolbarLogo,
                  alt: "logo",
                  "aria-hidden": "true"
                }
              })
            : _vm._e(),
          _vm.showToolbarMenu
            ? _c(
                "v-menu",
                { attrs: { "offset-y": "" } },
                [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isUiMinimized,
                            expression: "!isUiMinimized"
                          }
                        ],
                        staticClass: "menu",
                        attrs: {
                          slot: "activator",
                          icon: "",
                          fab: "",
                          small: "",
                          "aria-label": "menu options"
                        },
                        slot: "activator"
                      },
                      _vm.tooltipMenuEventHandlers
                    ),
                    [_c("v-icon", [_vm._v(" menu ")])],
                    1
                  ),
                  _c(
                    "v-list",
                    [
                      _vm.isEnableLogin
                        ? _c(
                            "v-list-tile",
                            [
                              _vm.isLoggedIn
                                ? _c(
                                    "v-list-tile-title",
                                    {
                                      attrs: { "aria-label": "logout" },
                                      on: { click: _vm.requestLogout }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " + _vm._s(_vm.items[1].icon) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(_vm.items[1].title) + " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.isLoggedIn
                                ? _c(
                                    "v-list-tile-title",
                                    {
                                      attrs: { "aria-label": "login" },
                                      on: { click: _vm.requestLogin }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " + _vm._s(_vm.items[0].icon) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(_vm.items[0].title) + " "
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isSaveHistory
                        ? _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  attrs: { "aria-label": "clear chat history" },
                                  on: { click: _vm.requestResetHistory }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " + _vm._s(_vm.items[2].icon) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.items[2].title) + " ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shouldRenderSfxButton && _vm.isSFXOn
                        ? _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  attrs: { "aria-label": "mute sound effects" },
                                  on: { click: _vm.toggleSFXMute }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " + _vm._s(_vm.items[3].icon) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.items[3].title) + " ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.shouldRenderSfxButton && !_vm.isSFXOn
                        ? _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  attrs: {
                                    "aria-label": "unmute sound effects"
                                  },
                                  on: { click: _vm.toggleSFXMute }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " + _vm._s(_vm.items[4].icon) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(_vm.items[4].title) + " ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.canLiveChat
                        ? _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  attrs: { "aria-label": "request live chat" },
                                  on: { click: _vm.requestLiveChat }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.toolbarStartLiveChatIcon) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.toolbarStartLiveChatLabel) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLiveChat
                        ? _c(
                            "v-list-tile",
                            [
                              _c(
                                "v-list-tile-title",
                                {
                                  attrs: { "aria-label": "end live chat" },
                                  on: { click: _vm.endLiveChat }
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.toolbarEndLiveChatIcon) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.toolbarEndLiveChatLabel) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.locales, function(locale) {
                        return _c(
                          "v-list-tile",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isLocaleSelectable,
                                expression: "isLocaleSelectable"
                              }
                            ],
                            key: locale,
                            attrs: { disabled: _vm.restrictLocaleChanges }
                          },
                          [
                            _c(
                              "v-list-tile-title",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.setLocale(locale)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(locale) + " ")]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "nav-buttons" },
            [
              _c(
                "v-btn",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.hasPrevUtterance &&
                          !_vm.isUiMinimized &&
                          _vm.shouldRenderBackButton,
                        expression:
                          "hasPrevUtterance && !isUiMinimized && shouldRenderBackButton"
                      }
                    ],
                    staticClass: "nav-button-prev",
                    attrs: {
                      small: "",
                      icon: "",
                      disabled: _vm.isLexProcessing,
                      "aria-label": "go back to previous message"
                    },
                    on: { click: _vm.onPrev }
                  },
                  _vm.prevNavEventHandlers
                ),
                [_c("v-icon", [_vm._v(" mdiArrowLeft ")])],
                1
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    activator: ".nav-button-prev",
                    "content-class": "tooltip-custom",
                    right: ""
                  },
                  model: {
                    value: _vm.prevNav,
                    callback: function($$v) {
                      _vm.prevNav = $$v
                    },
                    expression: "prevNav"
                  }
                },
                [_c("span", [_vm._v("Previous")])]
              )
            ],
            1
          ),
          _c(
            "v-toolbar-title",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isUiMinimized,
                  expression: "!isUiMinimized"
                }
              ],
              staticClass: "hidden-xs-and-down",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.toggleMinimize.apply(null, arguments)
                }
              }
            },
            [_c("h1", [_vm._v(_vm._s(_vm.toolbarTitle))])]
          ),
          _c(
            "v-toolbar-title",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isUiMinimized,
                  expression: "!isUiMinimized"
                }
              ],
              staticClass: "hidden-xs-and-down"
            },
            [_vm._v(" " + _vm._s(_vm.userName) + " ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "info-button info-button-content",
              staticStyle: { "padding-right": "0" },
              attrs: {
                plain: "",
                right: "",
                "aria-label": "info button",
                text: "",
                ripple: false
              },
              on: { click: _vm.infoButtonClicked }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: "#ffffff", medium: "", right: "" } },
                [_vm._v(_vm._s(_vm.mdiInformationSlabCircleOutline))]
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "close-button close-button-content",
              staticStyle: { "padding-left": "0" },
              attrs: {
                plain: "",
                right: "",
                "aria-label": "close button",
                text: "",
                ripple: false
              },
              on: { click: _vm.closeForm }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: "#ffffff", large: "", right: "" } },
                [_vm._v(_vm._s(_vm.mdiChevronDown))]
              )
            ],
            1
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                "content-class": "tooltip-custom",
                activator: ".min-max-toggle",
                left: ""
              },
              model: {
                value: _vm.shouldShowTooltip,
                callback: function($$v) {
                  _vm.shouldShowTooltip = $$v
                },
                expression: "shouldShowTooltip"
              }
            },
            [
              _c("span", { attrs: { id: "min-max-tooltip" } }, [
                _vm._v(_vm._s(_vm.toolTipMinimize))
              ])
            ]
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                "content-class": "tooltip-custom",
                activator: ".help-toggle",
                left: ""
              },
              model: {
                value: _vm.shouldShowHelpTooltip,
                callback: function($$v) {
                  _vm.shouldShowHelpTooltip = $$v
                },
                expression: "shouldShowHelpTooltip"
              }
            },
            [_c("span", { attrs: { id: "help-tooltip" } }, [_vm._v("help")])]
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                "content-class": "tooltip-custom",
                activator: ".end-live-chat-btn",
                left: ""
              },
              model: {
                value: _vm.shouldShowEndLiveChatTooltip,
                callback: function($$v) {
                  _vm.shouldShowEndLiveChatTooltip = $$v
                },
                expression: "shouldShowEndLiveChatTooltip"
              }
            },
            [
              _c("span", { attrs: { id: "end-live-chat-tooltip" } }, [
                _vm._v(_vm._s(_vm.toolbarEndLiveChatLabel))
              ])
            ]
          ),
          _c(
            "v-tooltip",
            {
              attrs: {
                "content-class": "tooltip-custom",
                activator: ".menu",
                right: ""
              },
              model: {
                value: _vm.shouldShowMenuTooltip,
                callback: function($$v) {
                  _vm.shouldShowMenuTooltip = $$v
                },
                expression: "shouldShowMenuTooltip"
              }
            },
            [_c("span", { attrs: { id: "menu-tooltip" } }, [_vm._v("menu")])]
          ),
          _vm.isLocaleSelectable
            ? _c("span", { staticClass: "localeInfo" }, [
                _vm._v(_vm._s(_vm.currentLocale))
              ])
            : _vm._e(),
          _vm.shouldRenderHelpButton && !_vm.isLiveChat && !_vm.isUiMinimized
            ? _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "help-toggle",
                    attrs: { disabled: _vm.isLexProcessing, icon: "" },
                    on: { click: _vm.sendHelp }
                  },
                  _vm.tooltipHelpEventHandlers
                ),
                [_c("v-icon", [_vm._v(" mdiHelpCircleOutline ")])],
                1
              )
            : _vm._e(),
          _vm.isLiveChat && !_vm.isUiMinimized
            ? _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "end-live-chat-btn",
                    attrs: { disabled: !_vm.isLiveChat, icon: "" },
                    on: { click: _vm.endLiveChat }
                  },
                  _vm.tooltipEndLiveChatEventHandlers
                ),
                [
                  _c("span", { staticClass: "hangup-text" }, [
                    _vm._v(_vm._s(_vm.toolbarEndLiveChatLabel))
                  ]),
                  _c("v-icon", { staticClass: "call-end" }, [
                    _vm._v(" " + _vm._s(_vm.toolbarEndLiveChatIcon) + " ")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.$store.state.isRunningEmbedded
            ? _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "min-max-toggle",
                    attrs: {
                      icon: "",
                      "aria-label": _vm.isUiMinimized
                        ? "chat"
                        : "minimize chat window toggle"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleMinimize.apply(null, arguments)
                      }
                    }
                  },
                  _vm.tooltipEventHandlers
                ),
                [
                  _c("v-icon", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.isUiMinimized ? "chat" : "arrow_drop_down") +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }