var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { staticClass: "message", attrs: { "d-flex": "" } },
    [
      _c(
        "v-col",
        { staticClass: "message-layout", attrs: { "ma-2": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "message-bubble-date-container",
              attrs: { "d-flex": "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "message-bubble-column" },
                [
                  _vm.message.text && _vm.message.type !== "button"
                    ? _c(
                        "v-col",
                        {
                          staticClass: "message-bubble-avatar-container",
                          attrs: { "d-flex": "" }
                        },
                        [
                          _c("v-row", { staticClass: "message-bubble-row" }, [
                            _vm.shouldShowAvatarImage
                              ? _c("div", {
                                  staticClass: "avatar",
                                  style: _vm.avatarBackground,
                                  attrs: {
                                    tabindex: "-1",
                                    "aria-hidden": "true"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "message-bubble focusable",
                                attrs: { tabindex: "0" },
                                on: {
                                  focus: _vm.onMessageFocus,
                                  blur: _vm.onMessageBlur
                                }
                              },
                              [
                                "text" in _vm.message &&
                                ((_vm.message.alts != null &&
                                  _vm.message.alts.html != null &&
                                  _vm.message.alts.html.length) ||
                                  (_vm.message.text !== null &&
                                    _vm.message.text.length)) &&
                                !_vm.shouldDisplayInteractiveMessage &&
                                !_vm.shouldDisplayCarousel
                                  ? _c("message-text", {
                                      attrs: { message: _vm.message }
                                    })
                                  : _vm._e(),
                                _vm.shouldDisplayInteractiveMessage &&
                                _vm.message.interactiveMessage.templateType ==
                                  "ListPicker"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card-title",
                                          { attrs: { "primary-title": "" } },
                                          [
                                            _c("div", [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.message
                                                      .interactiveMessage.data
                                                      .content.imageData
                                                }
                                              }),
                                              _c(
                                                "div",
                                                { staticClass: "headline" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.message
                                                        .interactiveMessage.data
                                                        .content.title
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.message
                                                      .interactiveMessage.data
                                                      .content.subtitle
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "v-list",
                                          {
                                            staticClass:
                                              "message-bubble interactive-row",
                                            attrs: { "two-line": "" }
                                          },
                                          [
                                            _vm._l(
                                              _vm.message.interactiveMessage
                                                .data.content.elements,
                                              function(item) {
                                                return [
                                                  _c(
                                                    "v-list-tile",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.resendMessage(
                                                            item.title
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      item.imageData
                                                        ? _c(
                                                            "v-list-tile-avatar",
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    item.imageData
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  item.title
                                                                )
                                                              }
                                                            }
                                                          ),
                                                          item.subtitle
                                                            ? _c(
                                                                "v-list-tile-sub-title",
                                                                {
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      item.subtitle
                                                                    )
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider")
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.shouldDisplayInteractiveMessage &&
                                _vm.message.interactiveMessage.templateType ==
                                  "TimePicker"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-card-title",
                                          { attrs: { "primary-title": "" } },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                { staticClass: "headline" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.message
                                                        .interactiveMessage.data
                                                        .content.title
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.message
                                                      .interactiveMessage.data
                                                      .content.subtitle
                                                  )
                                                )
                                              ])
                                            ])
                                          ]
                                        ),
                                        _vm._l(
                                          this.message.interactiveMessage
                                            .timeslots,
                                          function(item) {
                                            return [
                                              _c("v-subheader", [
                                                _vm._v(_vm._s(item.date))
                                              ]),
                                              _c(
                                                "v-list",
                                                {
                                                  staticClass:
                                                    "message-bubble interactive-row",
                                                  attrs: { "two-line": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-tile",
                                                    _vm._l(item.slots, function(
                                                      subItem
                                                    ) {
                                                      return _c(
                                                        "v-list-tile",
                                                        {
                                                          key:
                                                            subItem.localTime,
                                                          attrs: {
                                                            data: subItem
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.resendMessage(
                                                                subItem.date
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      subItem.localTime
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.shouldDisplayInteractiveMessage &&
                                _vm.message.interactiveMessage.templateType ==
                                  "DateTimePicker"
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-toolbar-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.message.interactiveMessage
                                                .data.content.title
                                            )
                                          )
                                        ]),
                                        _c("v-datetime-picker", {
                                          attrs: {
                                            "text-field-props":
                                              _vm.textFieldProps
                                          },
                                          model: {
                                            value: _vm.datetime,
                                            callback: function($$v) {
                                              _vm.datetime = $$v
                                            },
                                            expression: "datetime"
                                          }
                                        }),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { depressed: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.sendDateTime(
                                                  _vm.datetime
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Confirm")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.message.id ===
                                  this.$store.state.messages.length - 1 &&
                                _vm.isLastMessageFeedback &&
                                _vm.message.type === "bot" &&
                                _vm.botDialogState &&
                                _vm.showDialogFeedback
                                  ? _c(
                                      "div",
                                      { staticClass: "feedback-state" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            class: {
                                              "feedback-icons-positive": !_vm.positiveClick,
                                              positiveClick: _vm.positiveClick
                                            },
                                            attrs: { tabindex: "0" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onButtonClick(
                                                  _vm.positiveIntent
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" thumb_up ")]
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            class: {
                                              "feedback-icons-negative": !_vm.negativeClick,
                                              negativeClick: _vm.negativeClick
                                            },
                                            attrs: { tabindex: "0" },
                                            on: {
                                              click: function($event) {
                                                return _vm.onButtonClick(
                                                  _vm.negativeIntent
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" thumb_down ")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.message.type === "bot" &&
                                _vm.botDialogState &&
                                _vm.showDialogStateIcon
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "dialog-state",
                                        class: `dialog-state-${_vm.botDialogState.state}`,
                                        attrs: { medium: "" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.botDialogState.icon) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.message.type === "human" &&
                                _vm.message.audio
                                  ? _c(
                                      "div",
                                      [
                                        _c("audio", [
                                          _c("source", {
                                            attrs: {
                                              src: _vm.message.audio,
                                              type: "audio/wav"
                                            }
                                          })
                                        ]),
                                        _c(
                                          "v-btn",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.showMessageMenu,
                                                expression: "!showMessageMenu"
                                              }
                                            ],
                                            staticClass: "icon-color ml-0 mr-0",
                                            attrs: { tabindex: "0", icon: "" },
                                            on: { click: _vm.playAudio }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "play-icon" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.mdiPlayCircleOutline
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.message.type === "human"
                                  ? _c(
                                      "v-menu",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showMessageMenu,
                                            expression: "showMessageMenu"
                                          }
                                        ],
                                        attrs: { "offset-y": "" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { icon: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "smicon" },
                                              [_vm._v(" mdi-dots-vertical ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.resendMessage(
                                                          _vm.message.text
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(_vm.mdiReplay)
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.message.type === "human" &&
                                            _vm.message.audio
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass: "message-audio"
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        on: {
                                                          click: _vm.playAudio
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.mdiPlayCircleOutline
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.shouldShowMessageDate && _vm.isMessageFocused
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-xs-center message-date",
                          attrs: { "aria-hidden": "true" }
                        },
                        [_vm._v(" " + _vm._s(_vm.messageHumanDate) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.shouldDisplayCarousel
            ? _c(
                "v-col",
                { staticClass: "carousel-cards" },
                [
                  _c("carousel-slides", {
                    attrs: { providers: _vm.providerData }
                  }),
                  "text" in _vm.message &&
                  ((_vm.message.alts != null &&
                    _vm.message.alts.html != null &&
                    _vm.message.alts.html.length) ||
                    (_vm.message.text !== null && _vm.message.text.length)) &&
                  !_vm.shouldDisplayInteractiveMessage &&
                  _vm.shouldDisplayCarousel
                    ? _c("message-text", { attrs: { message: _vm.message } })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "v-card__actions pl-0" },
                    [
                      _c(
                        "v-btn",
                        { staticClass: "v-btn", on: { click: _vm.showList } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "#047ABC" } },
                            [_vm._v(_vm._s(_vm.mdiFormatListBulleted))]
                          ),
                          _vm._v(" List View ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        { staticClass: "v-btn", on: { click: _vm.showMap } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "#047ABC" } },
                            [_vm._v(_vm._s(_vm.mdiMapMarkerOutline))]
                          ),
                          _vm._v(" Show on map ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.shouldDisplayResponseCard
            ? _c(
                "v-col",
                {
                  staticClass: "response-card",
                  attrs: { "d-flex": "", "mt-2": "", "mr-2": "", "ml-4": "" }
                },
                _vm._l(_vm.message.responseCard.genericAttachments, function(
                  card,
                  index
                ) {
                  return _c("response-card", {
                    key: index,
                    attrs: { "response-card": card }
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.shouldDisplayResponseCardV2 && !_vm.shouldDisplayResponseCard
            ? _c(
                "v-col",
                _vm._l(_vm.message.responseCardsLexV2, function(item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "response-card",
                      attrs: {
                        "d-flex": "",
                        "mt-2": "",
                        "mr-2": "",
                        "ml-4": ""
                      }
                    },
                    _vm._l(item.genericAttachments, function(card, index) {
                      return _c("response-card", {
                        key: index,
                        attrs: { "response-card": card }
                      })
                    }),
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }