var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticStyle: { "padding-bottom": "20px" },
          attrs: {
            app: "",
            dark: "",
            fixed: "",
            "aria-label":
              "Toolbar with sound FX mute button, minimise chat window button and option chat back a step button"
          }
        },
        [
          _c("v-toolbar-title", { staticClass: "hidden-xs-and-down" }, [
            _c("h1", [_vm._v("Provider Locations")])
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "close-button close-button-content",
              attrs: {
                plain: "",
                right: "",
                "aria-label": "close button",
                text: "",
                ripple: false
              },
              on: { click: _vm.closeForm }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: "#ffffff", large: "", right: "" } },
                [_vm._v(_vm._s(_vm.mdiChevronDown))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("v-container", [
            _c("div", { staticClass: "map-wrapper" }, [
              _c("iframe", {
                staticClass: "map-iframe",
                staticStyle: { border: "0" },
                attrs: {
                  width: "100%",
                  height: "280px",
                  frameborder: "0",
                  src: _vm.mapAddress,
                  allowfullscreen: ""
                }
              }),
              _c(
                "div",
                [
                  _c("carousel-slides", {
                    attrs: { providers: _vm.providers },
                    on: { "card-changed": _vm.updateMap }
                  })
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }