var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "lex-web", "ui-minimized": _vm.isUiMinimized } },
    [
      _c("min-button", {
        attrs: {
          "toolbar-color": _vm.toolbarColor,
          "is-ui-minimized": _vm.isUiMinimized
        },
        on: { toggleMinimizeUi: _vm.toggleMinimizeUi }
      }),
      !_vm.isUiMinimized
        ? _c("toolbar-container", {
            attrs: {
              userName: _vm.userNameValue,
              "toolbar-title": _vm.toolbarTitle,
              "toolbar-color": _vm.toolbarColor,
              "toolbar-logo": _vm.toolbarLogo,
              toolbarStartLiveChatLabel: _vm.toolbarStartLiveChatLabel,
              toolbarStartLiveChatIcon: _vm.toolbarStartLiveChatIcon,
              toolbarEndLiveChatLabel: _vm.toolbarEndLiveChatLabel,
              toolbarEndLiveChatIcon: _vm.toolbarEndLiveChatIcon,
              "is-ui-minimized": _vm.isUiMinimized,
              transition: "fade-transition"
            },
            on: {
              toggleMinimizeUi: _vm.toggleMinimizeUi,
              requestLogin: _vm.handleRequestLogin,
              requestLogout: _vm.handleRequestLogout,
              requestLiveChat: _vm.handleRequestLiveChat,
              endLiveChat: _vm.handleEndLiveChat,
              "info-button-clicked": _vm.displayInfoNotice
            }
          })
        : _vm._e(),
      !_vm.isUiMinimized
        ? _c(
            "v-main",
            [
              _c(
                "v-container",
                {
                  staticClass: "message-list-container",
                  class: `toolbar-height-${_vm.toolbarHeightClassSuffix}`,
                  attrs: { fluid: "", "pa-0": "" }
                },
                [
                  _vm.showInfoNotice
                    ? _c("v-textarea", {
                        attrs: {
                          readonly: "",
                          "auto-grow": "",
                          value:
                            "The answers provided by this assistant are generated by an advanced AI model, using data gathered from this website that the AI model found to be the closest to match your inquiry. No human was involved in the process. Therefore, in the event of any discrepancies between the assistant and the website content, the website should be considered the most reliable source."
                        }
                      })
                    : _vm._e(),
                  !_vm.isUiMinimized ? _c("message-list") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.isUiMinimized && !_vm.hasButtons
        ? _c("input-container", {
            ref: "InputContainer",
            attrs: {
              "text-input-placeholder": _vm.textInputPlaceholder,
              "initial-speech-instruction": _vm.initialSpeechInstruction
            }
          })
        : _vm._e(),
      _vm.isSFXOn
        ? _c("div", { attrs: { id: "sound", "aria-hidden": "true" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }