var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { app: "", fixed: "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "input-container",
          attrs: { row: "", "justify-space-between": "", "ma-0": "" }
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                color: "white",
                dense: this.$store.state.isRunningEmbedded
              }
            },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouldShowTextInput,
                    expression: "shouldShowTextInput"
                  }
                ],
                ref: "textInput",
                attrs: {
                  label: _vm.textInputPlaceholder,
                  disabled: _vm.isLexProcessing,
                  id: "text-input",
                  name: "text-input",
                  "single-line": "",
                  "hide-details": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                    return _vm.postTextMessage.apply(null, arguments)
                  },
                  focus: _vm.onTextFieldFocus,
                  blur: _vm.onTextFieldBlur,
                  input: _vm.onKeyUp
                },
                model: {
                  value: _vm.textInput,
                  callback: function($$v) {
                    _vm.textInput = $$v
                  },
                  expression: "textInput"
                }
              }),
              _c("recorder-status", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.shouldShowTextInput,
                    expression: "!shouldShowTextInput"
                  }
                ]
              }),
              _c(
                "v-tooltip",
                {
                  ref: "tooltip",
                  attrs: {
                    activator: ".input-button",
                    "content-class": "tooltip-custom",
                    left: ""
                  },
                  model: {
                    value: _vm.shouldShowTooltip,
                    callback: function($$v) {
                      _vm.shouldShowTooltip = $$v
                    },
                    expression: "shouldShowTooltip"
                  }
                },
                [
                  _c("span", { attrs: { id: "input-button-tooltip" } }, [
                    _vm._v(_vm._s(_vm.inputButtonTooltip))
                  ])
                ]
              ),
              _vm.shouldShowSendButton
                ? _c(
                    "v-btn",
                    _vm._g(
                      {
                        ref: "send",
                        staticClass: "icon-color input-button",
                        attrs: {
                          disabled:
                            _vm.isLexProcessing || _vm.isSendButtonDisabled,
                          icon: "",
                          "aria-label": "Send Message"
                        },
                        on: { click: _vm.postTextMessage }
                      },
                      _vm.tooltipEventHandlers
                    ),
                    [
                      _c("v-icon", { attrs: { medium: "" } }, [
                        _vm._v("mdi-Send")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }