var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-col", { staticClass: "recorder-status white" }, [
    _c("div", { staticClass: "status-text" }, [
      _c("span", [_vm._v(_vm._s(_vm.statusText))])
    ]),
    _c(
      "div",
      { staticClass: "voice-controls ml-2" },
      [
        _c(
          "transition",
          {
            attrs: { css: false },
            on: { enter: _vm.enterMeter, leave: _vm.leaveMeter }
          },
          [
            _vm.isRecording
              ? _c("div", { staticClass: "volume-meter" }, [
                  _c("meter", {
                    attrs: {
                      value: _vm.volume,
                      min: "0.0001",
                      low: "0.005",
                      optimum: "0.04",
                      high: "0.07",
                      max: "0.09"
                    }
                  })
                ])
              : _vm._e()
          ]
        ),
        _vm.isProcessing
          ? _c("v-progress-linear", {
              staticClass: "processing-bar ma-0",
              attrs: { indeterminate: true }
            })
          : _vm._e(),
        _c(
          "transition",
          {
            attrs: { css: false },
            on: { enter: _vm.enterAudioPlay, leave: _vm.leaveAudioPlay }
          },
          [
            _vm.isBotSpeaking
              ? _c("v-progress-linear", {
                  staticClass: "audio-progress-bar ma-0",
                  model: {
                    value: _vm.audioPlayPercent,
                    callback: function($$v) {
                      _vm.audioPlayPercent = $$v
                    },
                    expression: "audioPlayPercent"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }