// eslint-disable-next-line 
export async function findProviders(baseUrl, intent, routeSpecialty, routeZipOrCity, routeInsurancePlan) {
    try {
        const specialtyLookupTable = [
            { specialty: 'pediatric', value: 'pediatrics'}
          ];
        let zipOrCity = routeZipOrCity;
        let insurancePlan = routeInsurancePlan;
        let specialty = routeSpecialty;
        let requestURI = `${baseUrl}/web/providers`;
        if ((intent && intent.name === 'Insurance' && intent.slots.specialty) || routeSpecialty) {
            specialty = intent?.slots?.specialty?.value?.originalValue || routeSpecialty;
            if (specialty) {
                const lookedupSpecialty = specialtyLookupTable.find((entry) => specialty === entry.specialty);
                requestURI += `/${lookedupSpecialty?.value || specialty}`;
            }
        }
        if ((intent && intent.name === 'Insurance' && intent.slots.zip_or_city) || routeZipOrCity) {
            zipOrCity = intent?.slots?.zip_or_city?.value?.originalValue || routeZipOrCity;
            if (zipOrCity) {
                requestURI += `/${zipOrCity}`;
            }
        }
        if ((intent && intent.name === 'Insurance' && intent.slots.insurance_plan) || routeInsurancePlan) {
            insurancePlan = intent?.slots?.insurance_plan?.value?.originalValue || insurancePlan;
            if (insurancePlan) {
                requestURI += `/${insurancePlan}`;
            }
        }    
        const response = await fetch(encodeURI(requestURI));
        if (!response.ok) {
            console.log(`Could not fetch providers. HTTP Response Code: ${response?.status}`);
            return undefined;
        } 

        const res = await response.json();
        const providers = res?.message;

        return { providers, specialty, zipOrCity, insurancePlan };
    } catch(err) {
        console.error('Error while fetching providers ', err);
        return undefined;
    }
};

export function getWebErrorMessageForProviders(intent) {
    try {
        let baseMsg = "I'm sorry, we couldn't find any ";
        let zipOrCity = '98001';
        let specialty = 'family medicine';
        if ((intent && intent.name === 'Insurance' && intent.slots.specialty)) {
            specialty = intent.slots.specialty?.value?.originalValue;
            if (specialty) {
                baseMsg += `${specialty} providers`;
            }
        }
        if ((intent && intent.name === 'Insurance' && intent.slots.zip_or_city)) {
            zipOrCity = intent.slots.zip_or_city?.value?.originalValue;
            if (zipOrCity) {
                baseMsg += ` near the zip code ${zipOrCity}`;
            }
        }
        baseMsg += ".";
        return baseMsg;
    }
    catch(err) {
        console.error('Error while building web providers error message', err);
        return "I'm sorry, we couldn't find any providers.";
    }
}
