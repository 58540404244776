var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mx-auto" },
    [
      _vm.provider
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-0", attrs: { cols: "auto" } },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.isValidValue(_vm.provider.avatarImgUrl)
                            ? _c(
                                "v-avatar",
                                {
                                  staticClass: "mt-1 mb-1 pr-0",
                                  attrs: {
                                    height: "100",
                                    width: "85",
                                    tile: ""
                                  }
                                },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      position: "top center",
                                      src: _vm.provider.avatarImgUrl
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "div",
                      [
                        _vm.isValidValue(_vm.provider.displayName)
                          ? _c(
                              "v-card-text",
                              {
                                staticClass:
                                  "text-subtitle-1 font-weight-medium pl-0 ml-0 mb-0 pb-0 pt-0 pb-0",
                                staticStyle: { color: "#047ABC" }
                              },
                              [_vm._v(_vm._s(_vm.provider.displayName))]
                            )
                          : _vm._e(),
                        _vm.isValidValue(_vm.provider.specialty)
                          ? _c(
                              "v-card-subtitle",
                              { staticClass: "pl-0 pt-0 pb-0 mt-0 body-2" },
                              [_vm._v(_vm._s(_vm.provider.specialty))]
                            )
                          : _vm._e(),
                        _vm.isValidValue(_vm.provider.telephone)
                          ? _c(
                              "v-card-text",
                              { staticClass: "pl-0 pt-0 pb-0" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      slot: "prepend",
                                      small: "",
                                      color: "#047ABC"
                                    },
                                    slot: "prepend"
                                  },
                                  [_vm._v(_vm._s(_vm.mdiPhone))]
                                ),
                                _vm._v(_vm._s(_vm.provider.telephone))
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isValidValue(_vm.cardAddress(_vm.provider.address))
                          ? _c(
                              "v-card-text",
                              { staticClass: "pl-0 pt-0 pb-0" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      slot: "prepend",
                                      small: "",
                                      color: "#047ABC"
                                    },
                                    slot: "prepend"
                                  },
                                  [_vm._v(_vm._s(_vm.mdiMapMarkerOutline))]
                                ),
                                _vm._v(
                                  _vm._s(_vm.cardAddress(_vm.provider.address))
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isValidValue(_vm.provider.status)
                          ? _c(
                              "v-card-text",
                              { staticClass: "pt-0 pl-0 pb-0 body-2" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "card-text-highlight" },
                                  [_vm._v(_vm._s(_vm.provider.status))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              text: "",
                              icon: "",
                              right: "",
                              outlined: true,
                              ripple: false,
                              rounded: "",
                              color: "#0060bf"
                            },
                            on: {
                              click: function($event) {
                                _vm.show = !_vm.show
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.expansionIcon))])],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("v-expand-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show,
                            expression: "show"
                          }
                        ]
                      },
                      [
                        _c("v-divider", { staticClass: "card__divider" }),
                        _vm.isValidValue(_vm.provider.telephone)
                          ? _c(
                              "v-card-text",
                              { staticClass: "pt-0 pb-0" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      slot: "prepend",
                                      small: "",
                                      color: "#047ABC"
                                    },
                                    slot: "prepend"
                                  },
                                  [_vm._v(_vm._s(_vm.mdiPhone))]
                                ),
                                _vm._v(_vm._s(_vm.provider.telephone))
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("v-card-text", { staticClass: "pt-0 pb-0" }, [
                          _c(
                            "div",
                            { staticClass: "one-line-overflow" },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    slot: "prepend",
                                    small: "",
                                    color: "#047ABC"
                                  },
                                  slot: "prepend"
                                },
                                [_vm._v(_vm._s(_vm.mdiMapMarkerOutline))]
                              ),
                              _vm._v(
                                _vm._s(_vm.addressPart1(_vm.provider.address))
                              )
                            ],
                            1
                          )
                        ]),
                        _vm.isValidValue(_vm.addressPart2(_vm.provider.address))
                          ? _c("v-card-text", { staticClass: "pt-0 pb-0" }, [
                              _c(
                                "div",
                                { staticClass: "one-line-overflow" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "card__hidden__icon",
                                      attrs: { slot: "prepend", small: "" },
                                      slot: "prepend"
                                    },
                                    [_vm._v(_vm._s(_vm.mdiMapMarkerOutline))]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.addressPart2(_vm.provider.address)
                                    )
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _c("v-divider", { staticClass: "card__divider" })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }