var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "layout message-list column fill-height",
      attrs: { "aria-live": "polite" }
    },
    [
      _vm._l(_vm.messages, function(message) {
        return _c("message", {
          key: message.id,
          ref: "messages",
          refInFor: true,
          class: `message-${message.type}`,
          attrs: { message: message },
          on: { scrollDown: _vm.scrollDown }
        })
      }),
      _vm.loading ? _c("MessageLoading") : _vm._e(),
      _c("restart-button")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }