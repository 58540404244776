var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex message d-flex message-bot messsge-loading",
      attrs: { "aria-hidden": "true" }
    },
    [
      _c(
        "v-layout",
        { staticClass: "message-layout", attrs: { column: "", "ma-2": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "message-bubble-date-container",
              attrs: { "d-flex": "" }
            },
            [
              _c(
                "v-layout",
                { staticClass: "message-bubble-column", attrs: { column: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "message-bubble-avatar-container",
                      attrs: { "d-flex": "" }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "message-bubble-row",
                          attrs: { row: "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "message-bubble",
                              attrs: { "aria-hidden": "true" }
                            },
                            [_vm._v(" " + _vm._s(_vm.progress) + " ")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }