var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _vm.shouldDisplayResponseCardTitle
        ? _c(
            "div",
            [
              _vm.responseCard.title && _vm.responseCard.title.trim()
                ? _c(
                    "v-card-title",
                    {
                      staticClass: "red lighten-5",
                      attrs: { "primary-title": "" }
                    },
                    [
                      _c("span", { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.responseCard.title))
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.responseCard.subTitle
        ? _c("v-card-text", [
            _c("span", [_vm._v(_vm._s(_vm.responseCard.subTitle))])
          ])
        : _vm._e(),
      _vm.responseCard.subtitle
        ? _c("v-card-text", [
            _c("span", [_vm._v(_vm._s(_vm.responseCard.subtitle))])
          ])
        : _vm._e(),
      _vm.responseCard.imageUrl
        ? _c("v-card-media", {
            attrs: {
              src: _vm.responseCard.imageUrl,
              contain: "",
              height: "33vh"
            }
          })
        : _vm._e(),
      _vm.responseCard.textWithLink
        ? _c(
            "v-card-actions",
            [
              _vm.responseCard.textWithLink.text
                ? _c(
                    "v-card-text",
                    [
                      _vm._v(
                        " " + _vm._s(_vm.responseCard.textWithLink.text) + " "
                      ),
                      _c(
                        "v-card",
                        { attrs: { href: _vm.responseCard.textWithLink.link } },
                        [
                          _c("v-card-text", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.responseCard.textWithLink.link) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.responseCard.buttons && _vm.shouldShowResponseCardButtons
        ? _c(
            "v-card-actions",
            { staticClass: "button-row" },
            _vm._l(_vm.responseCard.buttons, function(button) {
              return _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: button.text && _vm.shouldShowResponseCardButtons,
                      expression: "button.text && shouldShowResponseCardButtons"
                    }
                  ],
                  key: button.id,
                  staticClass: "secondary--text",
                  attrs: {
                    disabled: _vm.shouldDisableClickedResponseCardButtons,
                    default: "",
                    color: button.text.toLowerCase() === "more" ? "" : "outline"
                  },
                  nativeOn: {
                    "~click": function($event) {
                      return _vm.onButtonClick(
                        button.url,
                        button.value ? button.value : button.text
                      )
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(button.text) + " "),
                  button.url
                    ? _c("v-icon", { attrs: { small: "", color: "#047ABC" } }, [
                        _vm._v(_vm._s(_vm.mdiLaunch))
                      ])
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.responseCard.attachmentLinkUrl
        ? _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "red lighten-5",
                  attrs: {
                    flat: "",
                    tag: "a",
                    href: _vm.responseCard.attachmentLinkUrl,
                    target: "_blank"
                  }
                },
                [_vm._v(" Open Link ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }