var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticStyle: { "padding-bottom": "20px" },
          attrs: {
            app: "",
            dark: "",
            fixed: "",
            "aria-label":
              "Toolbar with sound FX mute button, minimise chat window button and option chat back a step button"
          }
        },
        [
          _c("v-toolbar-title", { staticClass: "hidden-xs-and-down" }, [
            _c("h1", [_vm._v("Provider List")])
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "close-button close-button-content",
              attrs: {
                plain: "",
                right: "",
                "aria-label": "close button",
                text: "",
                ripple: false
              },
              on: { click: _vm.closeForm }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: "#ffffff", large: "", right: "" } },
                [_vm._v(_vm._s(_vm.mdiChevronDown))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            {
              staticClass: "message-list-container",
              class: `toolbar-height-${_vm.toolbarHeightClassSuffix}`,
              staticStyle: { "overflow-y": "auto", "overflow-x": "auto" },
              attrs: { fluid: "", "pa-0": "" }
            },
            _vm._l(_vm.providers, function(provider) {
              return _c("provider-card", {
                key: provider.displayName,
                ref: "providers",
                refInFor: true,
                class: `provider-card`,
                staticStyle: { "padding-top": "0" },
                attrs: { provider: provider }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }