<template>
  <v-card class="mx-auto">
    <v-container v-if="provider">
           <v-row>
            <v-col cols="auto" class="pr-0">
            <div class="d-flex">
              <v-avatar v-if="isValidValue(provider.avatarImgUrl)"
                class="mt-1 mb-1 pr-0"
                height=100 width=85
                tile
              >
                <v-img position = "top center" :src="provider.avatarImgUrl">
                </v-img>
              </v-avatar>
              </div>
              </v-col>
              <v-col cols="auto">
              <div>
                <v-card-text v-if="isValidValue(provider.displayName)"
                  class="text-subtitle-1 font-weight-medium pl-0 ml-0 mb-0 pb-0 pt-0 pb-0" style="color:#047ABC"
                >{{ provider.displayName }}</v-card-text>

                <v-card-subtitle v-if="isValidValue(provider.specialty)" class="pl-0 pt-0 pb-0 mt-0 body-2">{{ provider.specialty }}</v-card-subtitle>
                <v-card-text v-if="isValidValue(provider.telephone)" class = "pl-0 pt-0 pb-0"><v-icon small color="#047ABC" slot="prepend">{{ mdiPhone }}</v-icon>{{provider.telephone}}</v-card-text>
                <v-card-text v-if="isValidValue(cardAddress(provider.address))" class = "pl-0 pt-0 pb-0"><v-icon small color="#047ABC" slot="prepend">{{ mdiMapMarkerOutline }}</v-icon>{{ cardAddress(provider.address) }}</v-card-text>
                <v-card-text v-if="isValidValue(provider.status)" class="pt-0 pl-0 pb-0 body-2"><div class="card-text-highlight">{{ provider.status }}</div></v-card-text>
                <v-btn 
        text
        icon
        right
        :outlined=true
        :ripple=false
        rounded
        color="#0060bf"
        @click="show = !show"
      >
      <v-icon>{{ expansionIcon }}</v-icon>
      </v-btn>
   </div>
   </v-col>
  <v-expand-transition>
      <div v-show="show">
        <v-divider class = "card__divider" ></v-divider>
          <v-card-text v-if="isValidValue(provider.telephone)" class = "pt-0 pb-0"><v-icon small color="#047ABC" slot="prepend">{{ mdiPhone }}</v-icon>{{provider.telephone}}</v-card-text>
          <v-card-text class = "pt-0 pb-0"><div class = "one-line-overflow"><v-icon small color= "#047ABC" slot="prepend">{{ mdiMapMarkerOutline }}</v-icon>{{ addressPart1(provider.address) }}</div></v-card-text>
          <v-card-text v-if="isValidValue(addressPart2(provider.address))" class = "pt-0 pb-0"><div class = "one-line-overflow"><v-icon slot="prepend" small class="card__hidden__icon">{{ mdiMapMarkerOutline }}</v-icon>{{ addressPart2(provider.address) }}</div></v-card-text>
        <v-divider class = "card__divider"></v-divider>
      </div>
    </v-expand-transition> 
 </v-row>
    </v-container>  
  </v-card>
</template>
<script>
/*
Copyright 2017-2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Amazon Software License (the "License"). You may not use this file
except in compliance with the License. A copy of the License is located at

http://aws.amazon.com/asl/

or in the "license" file accompanying this file. This file is distributed on an "AS IS"
BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied. See the
License for the specific language governing permissions and limitations under the License.
*/
import { mdiPhone, mdiMapMarkerOutline, mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js';

export default {
  name: 'provider-card',
  data() {
    return { 
      show: false,
      mdiPhone,
      mdiMapMarkerOutline,
      mdiChevronDoubleUp,
      mdiChevronDoubleDown
    };
  },
  props: ['provider'],
  computed: {
    expansionIcon() {
      return this.show ? mdiChevronDoubleUp : mdiChevronDoubleDown;
    },
  },
  methods: {
    addressPart1(address) {
      if (address && address.length > 0) {
        return address[0];
      }
      return '';
    },
    addressPart2(address) {
      if (address && address.length > 1) {
        return address[1];
      }
      return '';
    },
    cardAddress(address) {
      const address2ndLine = this.addressPart2(address);
      if (!this.isValidValue(address2ndLine)) {
        return this.addressPart1(address);
      }
      return address2ndLine;
    },
    isValidValue(val) {
      if (val && val.length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
</style>
