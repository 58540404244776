<template>
      <v-carousel @change="cardChanged($event)" v-if= "providers" :cycle=false height="350px" show-arrows-on-hover hide-delimiters class="custom">
        <template v-for="(item, index) in providers">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
            :key="index" eager src="" >
            <v-row class="flex-nowrap" style="height:100%">
              <template v-for="(n,col) in columns">
                <template v-if="(+index + col) < providers.length">
                  <v-col :key="col">
                        <v-card class = "mx-auto" max-width="350" v-if="(+index + col) < providers.length" 
                                height="100%"
                        >
                          <v-card-title class = "pb-2">
                            <v-avatar><img :src="providers[index+col].avatarImgUrl"/>
                            </v-avatar>
                            <v-row class="pt-0 justify-end align-content-start">
                              <v-btn v-if="isValidValue(providers[index+col].providerPageUrl)" :href="providers[index+col].providerPageUrl" target="_blank" plain :ripple=false text class="pt-0 text-none">Visit Page<v-icon small color="#047ABC">{{ mdiLaunch }}</v-icon></v-btn>
                            </v-row>
                          </v-card-title>
                          <v-card-title class = "pt-0 pb-0 subtitle-1 font-weight-medium">{{ providers[index+col].displayName }}</v-card-title>
                          <v-card-subtitle v-if="isValidValue(providers[index+col].specialty)" class = "pt-3 pb-0 body-2 font-weight-light"> {{ providers[index+col].specialty }}</v-card-subtitle>
                          <v-card-text v-if="isValidValue(providers[index+col].telephone)" block class = "pt-0 pb-0"><v-icon small color="#047ABC" slot="prepend">{{ mdiPhone }}</v-icon>{{ providers[index+col].telephone }}</v-card-text>
                          <v-divider class = "card__divider" ></v-divider>
                          <v-card-text class = "pt-0 pb-0"><div class = "one-line-overflow"><v-icon small color= "#047ABC" slot="prepend">{{ mdiMapMarkerOutline }}</v-icon>{{ addressPart1(providers[index+col].address) }}</div></v-card-text>
                          <v-card-text v-if="isValidValue(addressPart2(providers[index+col].address))" class = "pt-0 pb-0"><div class = "one-line-overflow"><v-icon slot="prepend" small class="card__hidden__icon">{{ mdiMapMarkerOutline }}</v-icon>{{ addressPart2(providers[index+col].address) }}</div></v-card-text>
                          <v-divider class = "card__divider"></v-divider>
                          <v-card-text v-if="isValidValue(providers[index+col].status)" class="pt-2 pb-0 body-2"><div class="card-text-highlight">{{ providers[index+col].status }}</div></v-card-text>
                          <v-card-actions>
                            <v-layout justify-center align-end>
                              <v-btn v-if="isValidValue(providers[index+col].schedulingUrl)" :href="providers[index+col].schedulingUrl" backcolor="#047ABC" class="carousel-card-btn body-1" block>Schedule Online</v-btn>
                              <v-btn v-if="!isValidValue(providers[index+col].schedulingUrl)" :href="'tel:' + providers[index+col].telephone" backcolor="#047ABC" class="carousel-card-btn body-1" block>Call Provider</v-btn>
                            </v-layout>
                          </v-card-actions>
                        </v-card>                  
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
</template>

<script>
/*
Copyright 2017-2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Amazon Software License (the "License"). You may not use this file
except in compliance with the License. A copy of the License is located at

http://aws.amazon.com/asl/

or in the "license" file accompanying this file. This file is distributed on an "AS IS"
BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied. See the
License for the specific language governing permissions and limitations under the License.
*/

import { mdiLaunch, mdiPhone, mdiMapMarkerOutline } from '@mdi/js';

export default {
  name: 'carousel-slides',
  data() {
    return { 
      mdiLaunch,
      mdiPhone,
      mdiMapMarkerOutline
    };
  },
  props: ['providers'],
  emits: ['card-changed'],
  computed: {
      shouldCycle() { 
      return false; 
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      return 1;
    },
  },
  methods: {
    cardChanged(idxCard){
      this.$emit('card-changed', idxCard || 0);
    },
    onButtonClick() {
    },
    addressPart1(address) {
      if (address && address.length > 0) {
        return address[0];
      }
      return '';
    },
    addressPart2(address) {
      if (address && address.length > 1) {
        return address[1];
      }
      return '';
    },
    isValidValue(val) {
      if (val && val.length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
/* Add styles to the custom-chatbot-style.css file */
</style>