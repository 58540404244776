<template>
    <v-col d-flex class="ml-16 pl-4">
      <v-btn
        aria-label="restart button"
        class="restart-button restart-button-content text-capitalize"
        color="#047ABC"
        text
        icon
        @click="onButtonClick"
      >
      <v-icon left>{{ mdiRestart }}</v-icon>
      Start Over
      </v-btn>
    </v-col>
</template>

<script>
/*
Copyright 2017-2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Amazon Software License (the "License"). You may not use this file
except in compliance with the License. A copy of the License is located at

http://aws.amazon.com/asl/

or in the "license" file accompanying this file. This file is distributed on an "AS IS"
BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied. See the
License for the specific language governing permissions and limitations under the License.
*/
import { mdiRestart } from '@mdi/js';

export default {
  name: 'restart-button',
  data() {
    return { 
      mdiRestart 
    };
  },
  props: [],
  methods: {
    onButtonClick() {
      this.$store.dispatch('pushMessage', {
          type: 'human',
          text: 'Start Over',
        });
      this.$store.dispatch('sendInitialUtterance');  
    },
  },
};
</script>
