var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "min-button-container", attrs: { fluid: "" } },
    [
      _c(
        "v-fab-transition",
        [
          _vm.minButtonContent
            ? _c(
                "v-btn",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUiMinimized,
                        expression: "isUiMinimized"
                      }
                    ],
                    staticClass: "min-button min-button-content",
                    attrs: {
                      bottom: "",
                      dark: "",
                      depressed: "",
                      fixed: "",
                      right: "",
                      color: _vm.toolbarColor,
                      "aria-label": "show chat window"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleMinimize.apply(null, arguments)
                      }
                    }
                  },
                  _vm.tooltipEventHandlers
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(" " + _vm._s("chat") + " ")
                  ]),
                  _vm._v(" " + _vm._s(_vm.minButtonContent) + " ")
                ],
                1
              )
            : _c(
                "v-btn",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isUiMinimized,
                        expression: "isUiMinimized"
                      }
                    ],
                    staticClass: "min-button",
                    attrs: {
                      bottom: "",
                      dark: "",
                      depressed: "",
                      fab: "",
                      fixed: "",
                      right: "",
                      color: _vm.toolbarColor,
                      "aria-label": "show chat window"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleMinimize.apply(null, arguments)
                      }
                    }
                  },
                  _vm.tooltipEventHandlers
                ),
                [_c("v-icon", [_vm._v(" " + _vm._s("chat") + " ")])],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }