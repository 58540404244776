<template>
    <v-app >
    <v-app-bar
    app
    dark
    fixed
    aria-label="Toolbar with sound FX mute button, minimise chat window button and option chat back a step button"
    style="padding-bottom: 20px;"
  >
      <v-toolbar-title
        class="hidden-xs-and-down"
      >
        <h1>Provider Locations</h1>
      </v-toolbar-title>

      <v-spacer />
      <v-btn v-on:click = "closeForm" plain right aria-label="close button"
          class="close-button close-button-content" text :ripple=false
      >
          <v-icon color="#ffffff" large right>{{ mdiChevronDown }}</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main>
      <v-container
      >
      <div class="map-wrapper">
        <iframe class="map-iframe" width="100%" height="280px" frameborder="0" style="border:0"
  :src="mapAddress" allowfullscreen>
        </iframe>
        <div>
          <carousel-slides @card-changed="updateMap" :providers="providers">
          </carousel-slides>
        </div>
      </div>
<!--
        <provider-card style="padding-top: 0;"
        ref="providers"
        v-for="provider in providers"
        v-bind:provider="provider"
        v-bind:key="provider.displayName"
        v-bind:class="`provider-card`">
        </provider-card> -->
        </v-container> 
  </v-main>
    </v-app>
</template>
<script>
/*
Copyright 2017-2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Amazon Software License (the "License"). You may not use this file
except in compliance with the License. A copy of the License is located at

http://aws.amazon.com/asl/

or in the "license" file accompanying this file. This file is distributed on an "AS IS"
BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied. See the
License for the specific language governing permissions and limitations under the License.
*/
import { mdiChevronDown } from '@mdi/js';
import CarouselSlides from './Carousel';
// import ProviderCard from './ProviderCard';
import { findProviders as webFindProviders } from '../connector/web';

export default {
  name: 'map-view',
  data() {
    return { 
      providers: [],
      idxCurrentCard: 0,
      mdiChevronDown,
      mapAddress: '',
      toolbarHeightClassSuffix: 'md',
    };
  },
  props: ['specialty', 'ziporcity', 'insurance'],
  components: {
    CarouselSlides
},
  computed: {
    addressLoc() {
      const url = "http://www.google.com/maps/embed/v1/place?key=AIzaSyAuI1--9PXsUNsPFvcmv59aKbTaOx30ByQ";
      const address = this.providers && this.providers.length ? this.providers[this.idxCurrentCard] : [];
      let locAddress = '';
      if (address.length > 1) {
        locAddress = `${url}&q=${address[1]}`; // address[0] in this case will be the vanity place name 
      } else {
        locAddress = `${url}&q=${address[0]}`;
      }

      return locAddress;
    }
  },
  async beforeMount() {
    try {
        const baseUrl = this.$store.state.config.dataConnector.url;
        const response = await webFindProviders(baseUrl, null, this.$props.specialty, this.$props.ziporcity, this.$props.insurance);
        this.providers = response.providers;
      } catch (error) {
        console.log(error);
      }
  },    
  async getData() {
      try {
        const baseUrl = this.$store.state.config.dataConnector.url;
        const response = await webFindProviders(baseUrl, null, this.$props.specialty, this.$props.ziporcity, this.$props.insurance);
        return response;
        
      } catch (error) {
        console.log(error);
      }
      return [];
  }, 
  methods: {
    updateMap(idxCard) {
      const url = "http://www.google.com/maps/embed/v1/place?key=AIzaSyAuI1--9PXsUNsPFvcmv59aKbTaOx30ByQ";
      const { address } = this.providers[idxCard];
      if (address.length > 1) {
        this.mapAddress = `${url}&q=${address[1]}`; // address[0] in this case will be the vanity place name 
      } else {
        this.mapAddress = `${url}&q=${address[0]}`;
      }
    },
    onResize() {
      const { innerWidth } = window;
      this.setToolbarHeigthClassSuffix(innerWidth);
    },
    setToolbarHeigthClassSuffix(innerWidth) {
      // Vuetify toolbar changes height based on innerWidth

      // when running embedded the toolbar is fixed to dense
      if (this.$store.state.isRunningEmbedded) {
        this.toolbarHeightClassSuffix = 'md';
        return;
      }

      // in full screen the toolbar changes size
      if (innerWidth < 640) {
        this.toolbarHeightClassSuffix = 'sm';
      } else if (innerWidth > 640 && innerWidth < 960) {
        this.toolbarHeightClassSuffix = 'md';
      } else {
        this.toolbarHeightClassSuffix = 'lg';
      }
    },
  closeForm() {
      window.parent.postMessage({ msg: "close-side-panel", data: undefined} , '*');
    },
  },
};
</script>

<style>
html { overflow-y:hidden !important; }

.message-list-container {
  position: fixed;
}
.message-list-container.toolbar-height-sm {
  top: 56px;
  height: calc(100% - 2 * 56px);
}
/* yes, the height is smaller in mid sizes */
.message-list-container.toolbar-height-md {
  top: 48px;
  height: calc(100% - 2 * 48px);
}
.message-list-container.toolbar-height-lg {
  top: 64px;
  height: calc(100% - 2 * 64px);
}

.map-wrapper{
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.carousel-wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 90;
  bottom: 0;
  left: 0;  
}

</style>
